jQuery(function ($) {
  var webStorage = function () {
    if (sessionStorage.getItem("access")) {
      console.log("2回目以降のアクセス");
      $("#js-loading").hide();
      $("body").removeClass("is-lock");
    } else {
      console.log("初回アクセス");
      sessionStorage.setItem("access", 0);
    }
  };
  webStorage();
});

//ブラウザ戻る進む対策（戻る進むでreload）
window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};
